import React from 'react';
import PropTypes from 'prop-types';
import '../fonts/fonts-post.css';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Signup from '../components/Signup';

const BlogPostTemplate = ({ pageContext }) => {
  const { spoiler, slug, title, html, date, timeToRead } = pageContext;
  return (
    <Layout>
      <SEO title={title} description={spoiler} slug={slug} />
      <main>
        <article>
          <header>
            <h1 style={{ color: '#329ea8' }}>{title}</h1>
          </header>
          <small>
            {new Date(date).toLocaleDateString('it-IT', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}{' '}
            • 📓 Lettura da {timeToRead} min
          </small>
          <div
            style={{ marginTop: '24px' }}
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </article>
        <Signup />
      </main>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  pageContext: PropTypes.shape({
    spoiler: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    timeToRead: PropTypes.number.isRequired,
  }).isRequired,
};

export default BlogPostTemplate;
